import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './services/storage.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthInterceptor } from './services/interceptor.service';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { WebrtcService } from './services/services';
import { AngularIbanModule } from 'angular-iban';
import { RecaptchaModule } from 'ng-recaptcha';

registerLocaleData(localePt);

export class ServiceLocator {
	static injector: Injector;
}

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		IonicModule.forRoot({
			mode: 'md',
			backButtonText: '',
			scrollAssist: false
		}),
		IonicStorageModule.forRoot(),
		HttpClientModule,
		AppRoutingModule,
		ServiceWorkerModule.register('combined-sw.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		// AngularFireModule.initializeApp(environment.firebase),
		AngularFireMessagingModule,
		QRCodeModule,
		TranslateModule.forRoot({
			defaultLanguage: 'pt',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		AngularIbanModule,
		RecaptchaModule
	],
	providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: LOCALE_ID, useValue: 'pt-PT' }, ScreenTrackingService, UserTrackingService, StorageService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, WebrtcService],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private injector: Injector) {
		ServiceLocator.injector = this.injector;
	}
}
