'use strict';

var appContainer;
var reqanimationreference;
var timeOutMS = 10000;
var isRunning = true;
var myTimeout = null;
var callback = () => {
	return false;
};

var successCallback = () => {};
var cancelCallback = (timeout) => {};

function success() {
	successCallback();
	appContainer.classList.remove('capture');
	isRunning = false;
	if (myTimeout) clearTimeout(myTimeout);
}

function cancel(timeout: boolean = false) {
	isRunning = false;
	if (myTimeout) clearTimeout(myTimeout);
	cancelAnimationFrame(reqanimationreference);
	cancelCallback(timeout);
	setTimeout(
		() => {
			if (appContainer) appContainer.classList.remove('capture');
		},
		400,
		true
	);
}

async function logtimestamp(timestamp) {
	//console.log('time:',timestamp,isRunning)
	try {
		if (isRunning) {
			const r: boolean = await callback();
			if (r === false) reqanimationreference = requestAnimationFrame(logtimestamp);
			else success();
		} else {
			cancel();
		}
	} catch (error) {
		// console.log('error:', error);
		reqanimationreference = requestAnimationFrame(logtimestamp);
	}
}

function loop(appContainerEl, callbackFunction, successFunction, cancelFunction, timeout = 30000) {
	timeOutMS = timeout;
	appContainer = document.querySelector(appContainerEl);
	// console.log('config capture:', appContainer);

	callback = callbackFunction;
	successCallback = successFunction;
	cancelCallback = cancelFunction;
	isRunning = true;
	reqanimationreference = requestAnimationFrame(logtimestamp);
	myTimeout = setTimeout(function () {
		// cancel requestAnimationFrame after x seconds
		// console.log('end animation');
		if (isRunning) cancel(true);
	}, timeOutMS);
}

export { loop, cancel };
