import { Component } from '@angular/core';
import { addIcons } from 'ionicons';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {
	constructor(public storageService: StorageService, public authService: AuthService) {
		addIcons({
			calendar: './assets/images/icons/calendar.svg',
			facebook: './assets/images/icons/facebook.svg',
			google: './assets/images/icons/google.svg',
			services: './assets/images/icons/services.svg',
			ticket: './assets/images/icons/ticket.svg',
			user: './assets/images/icons/user.svg',
			wallet: './assets/images/icons/wallet.svg',
			history: './assets/images/icons/history.svg',
			qr: './assets/images/qr.svg'
		});

		this.loadUser();
	}

	async loadUser() {
		let user = await this.storageService.get('user');
		if (user) {
			this.authService.setAuth(user.auth_key);
		}
	}
}
