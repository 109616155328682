import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	public auth_key: string = '';

	constructor(private http: HttpClient) {}

	setAuth(auth: string) {
		this.auth_key = auth;
	}

	get(url: string, data?: any) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Requested-With': 'com.grupopie.merchant.festadostabuleiros'
		});

		if (this.auth_key) {
			headers = headers.set('Authorization', this.auth_key);
		}

		let params = new HttpParams();

		if (data) {
			for (let p in data) {
				if (data[p]) {
					params = params.set(p, data[p]);
				}
			}
		}

		return this.http.get(environment.baseUrl + url, { headers: headers, params: params });
	}

	post(url: string, data: any) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'X-Requested-With': 'com.grupopie.merchant.festadostabuleiros'
		});

		if (this.auth_key) {
			headers = headers.set('Authorization', this.auth_key);
		}

		return this.http.post(environment.baseUrl + url, data, { headers: headers });
	}
}
