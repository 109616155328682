'use strict';

var settings = { localStream: null, localVideoEl: null, localDeviceId: null, initted: null, appContainer: null, cordova: null };
var config = {};

async function setup(conf) {
	config = conf;
	settings.cordova = window.cordova;
	const registed = !(config.isCordova && window.device && window.device.platform == 'iOS' && !settings.initted);
	if (!registed) {
		// console.log('webrtc was registed');
		settings.initted = true;
		settings.cordova.plugins.iosrtc.registerGlobals();
		settings.cordova.plugins.iosrtc.turnOnSpeaker(true);
	}
	settings.appContainer = document.querySelector(config.appContainer);
	settings.localVideoEl = settings.appContainer.querySelector(config.localVideo);
	if (settings.localVideoEl) {
		try {
			const localStream = await getUserMedia();
			settings.localVideoEl.classList.remove('hidden');
			settings.localVideoEl.classList.add('hidden');
			document.documentElement.style.background = 'transparent';
			document.body.style.background = 'transparent';
			settings.appContainer.style.background = 'transparent';
			settings.localVideoEl.style.zIndex = '-1';
			return localStream;
		} catch (e) {
			settings.localVideoEl.style.background = 'black';
			return 0;
		}
	}

	return null;
}
async function getUserMedia(deviceId) {
	const isIos = window.device && window.device.platform == 'iOS';
	if (!deviceId) {
		const devices = await navigator.mediaDevices.enumerateDevices();
		const filtered = devices.filter((device) => {
			const device_id = 'com.apple.avfoundation.avcapturedevice.built-in_video:' + (config.facingMode == 'environment' ? '0' : '1');
			if (isIos) {
				return device.kind === 'videoinput' && device.id === device_id;
			} else return device.kind === 'videoinput';
		});
		let newDevice = filtered.reverse().find((d) => d.deviceId !== 'default');
		settings.localDeviceId = newDevice ? newDevice.deviceId : null;
		return getUserMedia(settings.localDeviceId || 'default');
	}

	// console.debug('getUserMedia', deviceId);
	let constraints = {
		video: {
			width: { min: 1024, ideal: 1280, max: 1920 },
			height: { min: 576, ideal: 720, max: 1080 }
		},
		audio: false
	};
	if (isIos) {
		constraints.video.deviceId = deviceId;
	} else {
		constraints.video.facingMode = config.facingMode;
	}
	try {
		let stream = await navigator.mediaDevices.getUserMedia(constraints);
		settings.localStream = stream;
		settings.localVideoEl.srcObject = settings.localStream;
	} catch (e) {}
	settings.localVideoEl.style.background = 'black';
	const p = await permissions();
	// console.log('ask result', p);
	return settings.localStream;
}
function selectControlByName(name) {
	//console.log("appContainer:",appContainer,document)
	return settings.appContainer.querySelector('.controls [name=' + name + ']');
}
function cameraOn() {
	const camera_on = selectControlByName('camera_on');
	camera_on && camera_on.classList.add('hidden');
	const camera_off = selectControlByName('camera_off');
	camera_off && camera_off.classList.remove('hidden');
	settings.localVideoEl.classList.remove('hidden');
	if (!settings.localVideoEl.srcObject) {
		return getUserMedia();
	} else {
		settings.localStream.getVideoTracks().forEach(function (track) {
			track.enabled = true;
		});
	}
}
function cameraOff() {
	// console.log('stop::', settings.localStream);
	const camera_off = selectControlByName('camera_off');
	camera_off && camera_off.classList.add('hidden');
	const camera_on = selectControlByName('camera_on');
	camera_on && camera_on.classList.remove('hidden');
	settings.localVideoEl.classList.add('hidden');
	stopStreamedVideo(settings.localVideoEl);
}
function stopStreamedVideo(videoElem) {
	if (videoElem) {
		const stream = videoElem.srcObject;
		if (stream) {
			const tracks = stream.getTracks();
			tracks.forEach((track) => {
				track.stop();
				stream.removeTrack(track);
			});
			if (stream === settings.localStream) {
				videoElem.srcObject = null;
			}
		}
	}
}
async function permissions() {
	const type = config.isCordova && window.device ? window.device.platform.toLowerCase() : 'web';
	switch (type) {
		case 'ios':
			settings.cordova.plugins.iosrtc.requestPermission(true, true, (status) => {
				return status;
			});
			break;
		case 'android':
			settings.cordova.plugins.permissions.checkPermission(settings.cordova.plugins.permissions.CAMERA, function (result) {
				return result.hasPermission;
			});
			break;

		default:
			return true;
	}
}

module.exports = {
	settings,
	config,
	setup,
	permissions,
	stopStreamedVideo,
	getUserMedia,
	cameraOn,
	cameraOff
};
