import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { BasePage } from '@pages/base/base.page';

import { NavController } from '@ionic/angular';
import { AuthService } from '@app/services/auth.service';
import { LocationService } from '@app/services/location.service';

@Injectable({
	providedIn: 'root'
})
export class UserGuard extends BasePage implements CanActivate {
	constructor(public navCtrl: NavController, public authService: AuthService, public locationService: LocationService) {
		super();
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		let user = await this.storageService.get('user');
		if (user) {
			this.authService.setAuth(user.auth_key);

			this.locationService.hasCheckin().subscribe(
				(data: any) => {
					console.log(data);
					if (data.status === 'OK') {
						this.storageService.set('location', data.data);
						if (data.data) {
							this.navCtrl.navigateRoot('/activity');
						} else if (data.status === 'UNAUTHORIZED') {
							this.storageService.set('user', null);
							this.authService.setAuth(null);
							this.toastService.show('danger', data.message);
							this.router.navigate(['/welcome']);
						} else {
							this.navCtrl.navigateRoot('/home');
						}
					} else {
						this.navCtrl.navigateRoot('/home');
					}
				},
				() => {
					this.navCtrl.navigateRoot('/home');
				}
			);

			return false;
		}

		return true;
	}
}
