import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	controller: string = 'auth/';
	code: string = '';
	constructor(public api: ApiService) {}

	login(code) {
		return this.api.post(this.controller + 'login', { code });
	}

	logout() {
		return this.api.post(this.controller + 'logout', null);
	}

	setAuth(auth) {
		this.api.setAuth(auth);
	}
}
