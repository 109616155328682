import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

@Injectable()
export class StorageService {
	private _storage: Storage | null = null;
	constructor(private storage: Storage) {
		this.init();
	}

	async init() {
		const storage = await this.storage.create();
		this._storage = storage;
	}

	async get(key: string): Promise<any> {
		return await this.storage.get('cleanpwa-' + key);
	}

	public set(key: string, value: any) {
		this._storage?.set('cleanpwa-' + key, value);
	}

	public remove(key: string) {
		this.storage.remove('cleanpwa-' + key);
	}
}
