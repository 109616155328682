import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root'
})
export class LocationService {
	controller: string = 'location/';
	code: string = '';
	constructor(public api: ApiService) {}

	checkin(code) {
		return this.api.post(this.controller + 'checkin', { code });
	}

	checkout(checkinId: any) {
		return this.api.post(this.controller + 'checkout', { checkinId });
	}

	setImage(file, checkinId?: any) {
		return this.api.post(this.controller + 'set-image', { checkinId, file });
	}

	hasCheckin() {
		return this.api.get(this.controller + 'has-checkin');
	}
}
